// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_eVadRT28RcRX9CE3cI", // linear 
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/bIY5ldgrYebQaZ2dRl", // linear

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Linear Gen AI 🎭",
    HOME_PAGE_TITLE: "Home - Linear Gen AI 🎭",
    DASHBOARD_PAGE_TITLE: "Home - Linear Gen AI 🎭",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Linear Gen AI 🎭",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to generate images using line structure of images, choose your image file, enter a prompt and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "linear_gen_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/linear-gen-ai-1",

    // ... add more strings as needed
};



export default strings;